import React, { useEffect, useRef, useState } from 'react';
import { WidgetProvider } from '../../WidgetContext';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import MenuBar from '../../../components/MenuBar/MenuBar';
import PropTypes from 'prop-types';
import axios from 'axios';
import './style.css';
import { notifyError } from '../../../components/Toasts/toast.error';

const FileViewer = ({ navData, actionsState }) => {
  const viewerInstance: any = useRef();
  const [documents, setDocuments] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        const { options } = response;

        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}services/download`,
          { files: response[options.data] }
        );
        
        const files = data.files.map(({ name, uri }) => ({ fileName: name, uri: uri }));
        setDocuments(files);
      } catch (error) {
        setDocuments(null);
        notifyError('Error fetching document:', error);
      }
    };
  
    fetchData();
  }, [navData]);
  


  if (!documents) {
    return null;
  }

  return (
    <div className='widget-container view'>
      <WidgetProvider value={{ instance: viewerInstance }}>
        {
          navData.widgetData.menu
            ? <MenuBar
              key={`menu-${navData.widgetData.menu.id}`}
              menu={navData.widgetData.menu}
            />
            : null
        }
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={documents}
          config={{
            header: {
              disableHeader: true,
              disableFileName: false,
              retainURLParams: false,
            },
            csvDelimiter: ',', // "," as default,
            pdfZoom: {
              defaultZoom: 1, // 1 as default,
              zoomJump: 0.2, // 0.1 as default,
            },
            pdfVerticalScrollByDefault: true, // false as default
          }}
        />
      </WidgetProvider>
    </div>
  );
};

FileViewer.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object,
};

export default FileViewer;
