import React, { useRef } from 'react';
import { SnapConstraints } from '@syncfusion/ej2-react-diagrams';
import { ItemDirective, ItemsDirective, ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ColorPickerComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import PropTypes from 'prop-types';

const DiagramToolBarComponent = ({ navData, diagramInstance, isLane }) => {
  const dropdownInstance = useRef(null);
  const hSpacingInstance = useRef(null);
  const vSpacingInstance = useRef(null);

  const alignNodes = (args) => { 
    diagramInstance.align(args, diagramInstance.selectedItems.nodes, 'Selector');
    diagramInstance.dataBind();
  };

  const alignAnnotationsHorizontal = (args) => {
    diagramInstance.selectedItems.nodes.forEach((node) => {
      node.annotations.forEach((annotation) => {
        annotation.horizontalAlignment = args;
      });
    });
    diagramInstance.dataBind();
  };

  const alignAnnotationsVertical = (args) => {
    diagramInstance.selectedItems.nodes.forEach((node) => {
      node.annotations.forEach((annotation) => {
        annotation.verticalAlignment = args;
      });
    });
    diagramInstance.dataBind();
  };

  const toggleSnapping = (args) => {
    if (args.target.checked) {
      diagramInstance.snapSettings = { constraints: SnapConstraints.All };
    } else {
      diagramInstance.snapSettings = { constraints: SnapConstraints.None };
    }
    diagramInstance.dataBind();
  };

  const toggleHierarchicalView = (args) => {
    diagramInstance.setState({
      nodes: JSON.parse(diagramInstance.saveDiagram()).nodes,
      connectors: JSON.parse(diagramInstance.saveDiagram()).connectors,
    });

    if (args.target.checked) {
      diagramInstance.setState({ hierarchicalView: 'HierarchicalTree' });
      hSpacingInstance.current.enabled = true;
      vSpacingInstance.current.enabled = true;
      dropdownInstance.current.disabled = false;
    } else {
      diagramInstance.setState({ hierarchicalView: null });
      hSpacingInstance.current.enabled = false;
      vSpacingInstance.current.enabled = false;
      dropdownInstance.current.disabled = true;
    }
  };

  const toggleGrabableView = (args) => {
    diagramInstance.setState({ grabbable: args.target.checked });
  };

  const changeBackgroundColor = (args) => {
    diagramInstance.selectedItems.nodes.forEach((node) => {
      node.style.fill = args.value;
    });
    diagramInstance.dataBind();
  };

  const changeTextColor = (args) => {
    diagramInstance.selectedItems.nodes.forEach((node) => {
      node.annotations.forEach((annotation) => {
        annotation.style.color = args.value;
      });
    });
    diagramInstance.dataBind();
  };

  const bringToFront = () => {
    diagramInstance.bringToFront();
  };

  const sendToBack = () => {
    diagramInstance.sendToBack();
  };

  const bringForward = () => {
    diagramInstance.bringForward();
  };

  const sendBackward = () => {
    diagramInstance.sendBackward();
  };

  const orientationSelect = (args) => {
    switch (args.item.properties.text) {
    case 'TopToBottom':
    case 'BottomToTop':
    case 'LeftToRight':
    case 'RightToLeft':
      return updatelayout(args.item.properties.text);
    default:
      return null;
    }
  };

  const updatelayout = (orientation) => {
    diagramInstance.layout.orientation = orientation;
    diagramInstance.dataBind();
    diagramInstance.doLayout();
  };

  return (
    <ToolbarComponent id={`diagram-toolbar-${navData.widgetData.key}`} className='diagram-toolbar'>
      <ItemsDirective>
        <ItemDirective template={() => <CheckBoxComponent label='show grid' checked={true} onChange={toggleSnapping} />} />
        <ItemDirective type='Separator' />
        <ItemDirective prefixIcon='e-align-bottom-3' cssClass='rotate-90' click={() => alignNodes('Left')} />
        <ItemDirective prefixIcon='e-align-top-3' cssClass='rotate-90' click={() => alignNodes('Right')} />
        <ItemDirective prefixIcon='e-align-middle-3' cssClass='rotate-90' click={() => alignNodes('Center')} />
        <ItemDirective type='Separator' />
        <ItemDirective prefixIcon='e-align-top-3' click={() => alignNodes('Top')} />
        <ItemDirective prefixIcon='e-align-bottom-3' click={() => alignNodes('Bottom')} />
        <ItemDirective prefixIcon='e-align-middle-3' click={() => alignNodes('Middle')} />
        <ItemDirective type='Separator' />
        <ItemDirective prefixIcon='e-align-left' click={() => alignAnnotationsHorizontal('Right')} />
        <ItemDirective prefixIcon='e-align-right' click={() => alignAnnotationsHorizontal('Left')} />
        <ItemDirective prefixIcon='e-align-center' click={() => alignAnnotationsHorizontal('Center')} />
        <ItemDirective type='Separator' />
        <ItemDirective prefixIcon='e-align-left' cssClass='rotate-90' click={() => alignAnnotationsVertical('Bottom')} />
        <ItemDirective prefixIcon='e-align-right' cssClass='rotate-90' click={() => alignAnnotationsVertical('Top')} />
        <ItemDirective prefixIcon='e-align-center' cssClass='rotate-90' click={() => alignAnnotationsVertical('Center')} />
        <ItemDirective type='Separator' />
        <ItemDirective prefixIcon='e-bring-to-front' click={bringToFront} />
        <ItemDirective prefixIcon='e-send-to-back' click={sendToBack} />
        <ItemDirective prefixIcon='e-bring-forward' click={bringForward} />
        <ItemDirective prefixIcon='e-send-backward' click={sendBackward} />
        <ItemDirective type='Separator' />
        <ItemDirective template={() => <div><span className='e-label diagram-toolbar label'>background:</span><ColorPickerComponent value={'#cfcfcf'} change={changeBackgroundColor} /></div>} />
        <ItemDirective template={() => <div><span className='e-label diagram-toolbar label'>text:</span><ColorPickerComponent value={'#000'} change={changeTextColor} /></div>} />
        <ItemDirective type='Separator' />
        <ItemDirective template={() => <div><DropDownButtonComponent id='dropdown-toolbar-diagram' items={[{ text: 'TopToBottom' }, { text: 'BottomToTop' }, { text: 'LeftToRight' }, { text: 'RightToLeft' }]} select={orientationSelect} ref={dropdownInstance} disabled={true}>orientation</DropDownButtonComponent></div>} />
        <ItemDirective type='Separator' />
        <ItemDirective template={() => <div><NumericTextBoxComponent enabled={false} ref={hSpacingInstance} id='hSpacing' style={{ width: '100px' }} min={20} max={100} step={2} value={40} change={() => { diagramInstance.layout.horizontalSpacing = Number(hSpacingInstance.current.value); diagramInstance.dataBind(); }} /></div>} />
        <ItemDirective template={() => <div><NumericTextBoxComponent enabled={false} ref={vSpacingInstance} id='vSpacing' style={{ width: '100px' }} min={20} max={100} step={2} value={30} change={() => { diagramInstance.layout.verticalSpacing = Number(vSpacingInstance.current.value); diagramInstance.dataBind(); }} /></div>} />
        <ItemDirective template={() => <CheckBoxComponent label='hierarchical' disabled={isLane ?? true} checked={false} onChange={toggleHierarchicalView} />} />
        <ItemDirective template={() => <CheckBoxComponent label='tangible' checked={false} onChange={toggleGrabableView} />} />
      </ItemsDirective>
    </ToolbarComponent>
  );
};

DiagramToolBarComponent.propTypes = {
  navData: PropTypes.object,
  diagramInstance: PropTypes.any,
  isLane: PropTypes.any
};

export default DiagramToolBarComponent;
