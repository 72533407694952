export const rowSelecting = (gridData, navData, args: any, gridLinkInstance: any) => {

  const elements = [...gridData];

  if (!gridLinkInstance || !args.isInteracted) return null;

  if (navData.widgetData.rowSelection === 'single') {
    gridLinkInstance.clearSelection();

    elements.map((element: any) => {
      element.selected = false;
    });

    const row: any = (args.target.closest('tr'));
    const cell = row.querySelector('td.id-cell');
    const id = Number.parseFloat(cell.innerHTML);
    const index = elements.findIndex((element) => element.id === id);
    const element = elements[index];
    element.selected = true;
    elements[index] = element;
  }
};
