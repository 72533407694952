import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DateTimePicker, DateTimePickerComponent, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { useTranslation } from 'react-i18next';
import { loadCldr } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { L10n } from '@syncfusion/ej2-base';
import { jwtDecrypt } from '../../../api/services/tools/service.jwtDecrypt';
import PropTypes from 'prop-types';

DateTimePickerComponent.Inject(MaskedDateTime);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
L10n.load({
  'de': {
    'datetimepicker': {
      placeholder: 'Wählen Sie ein Datum und eine Uhrzeit aus',
      today: 'heute'
    }
  }
});

const DateTimeField = ({ field, isLocked, value, hiddenClass, setNewValue }) => {
  const [timeFormat, setTimeFormat] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timezone, setTimezone] = useState(null);
  const dateTimepickerInstance = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
        setTimeFormat(response.data.user.timeFormat);
        setDateFormat(response.data.user.dateFormat);
        setTimezone(response.data.user);
      } catch (error) {
        console.log(`jwtDecrypt error: ${error}`);
      }
    };
  
    fetchData();
  }, []);


  const setDateTimeValue: any = (value: any) => {
    if (value) {
      const date = typeof (value) === 'string' ? new Date(value) : new Date(Date.UTC(value.year, value.month - 1, value.day, value.hour, value.minute, value.second));
      // let intl = new Internationalization();
      // let dFormatter = intl.getDateFormat({
      //   skeleton: 'medium',
      //   type: 'dateTime',
      //   format: `${this.state.dateFormat} ${this.state.timeFormat}`
      // });
      return date;
      //return dFormatter(date);
    }
    return null;
  };

  const onChange = (args) => {
    setNewValue(new Date(args?.value).toISOString() ?? new Date().toISOString());
  };


  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
      <div className='form-input-element'>
        <DateTimePickerComponent
          ref={(scope) => { dateTimepickerInstance.current = scope; }}
          firstDayOfWeek={1}
          openOnFocus={true}
          className='form-input-element'
          name="dateTime"
          placeholder={value ? setDateTimeValue(value) : ''}
          change={onChange.bind(this)}
          format={`${dateFormat} ${timeFormat}`}
          value={setDateTimeValue(value)}
          enabled={!isLocked}
          timeFormat={timeFormat}
          enableMask={true}
        />
      </div>
    </div>
  );
};

DateTimeField.propTypes = {
  field: PropTypes.any,
  isLocked: PropTypes.bool,
  value: PropTypes.object,
  hiddenClass: PropTypes.string,
  setNewValue: PropTypes.func
};

export default DateTimeField;
