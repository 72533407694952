import React, { useState, useEffect, useRef } from 'react';
import fetchTreeGridData from './services/fetchTreeGridData';
import MenuBar from '../../components/MenuBar/MenuBar';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import {
  Edit,
  Group,
  Selection,
  CommandColumn,
} from '@syncfusion/ej2-react-grids';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ExcelExport,
  Filter,
  Inject,
  Page,
  PageSettingsModel,
  PdfExport,
  Resize,
  RowDD,
  Sort,
  TreeGridComponent,
  Toolbar,
  Aggregate,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective
} from '@syncfusion/ej2-react-treegrid';
import { WidgetProvider } from '../WidgetContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.css';

const TreeGridWidget = ({ navData, actionsState}) => {
  const [treeGridData, setTreeGridData] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [treeGridEditData, setTreeGridEditData] = useState(null);
  const { t } = useTranslation();

  const treegrid = useRef(null);
  const filter = { type: 'CheckBox', operator: 'contains' };
  const pageOptions: PageSettingsModel = {
    pageSize: 20,
    pageSizes: ['5', '10', '20', '50']
  };
  const filterSettings: any = {
    mode: 'Immediate',
    hierarchyMode: 'Both',
    immediateModeDelay: 400,
    type: 'FilterBar',
    operator: 'contains'
  };
  const toolbarOptions = [
    'ExpandAll',
    'CollapseAll',
    { text: 'Level 1', id: '1', prefixIcon: 'e-time' },
    { text: 'Level 2', id: '2', prefixIcon: 'e-time' },
    { text: 'Level 3', id: '3', prefixIcon: 'e-time' }
  ];

  useEffect(() => {
    setTreeGridData(null);
    fetchTreeGridData(setTreeGridData, { ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
    return () => {
      setTreeGridData(null);
    };
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);



  const rowSelecting = (args) => {
    if (args.target.classList.contains('e-treegridcollapse') || args.target.classList.contains('e-treegridexpand')) {
      return null;
    }

    const rowInfo = treegrid.current.getRowInfo(args.target);
    const rowData = rowInfo.rowData;
    const eventKey = navData.widgetData.events.find((event) => event.type === 'onClick')?.key;

    if (args.isShiftPressed) {
      treegrid.current.copy();
      return null;
    }

    window.dispatchEvent(
      new CustomEvent('custom-mousedown', {
        detail: {
          key: eventKey,
          id: rowData?.id,
          event: { ctrlKey: args.isCtrlPressed },
          context: 'grid'
        }
      })
    );

    if (!rowData || Object.keys(rowData).length === 0) return null;
    if (!args.isCtrlPressed) {
      runActions(eventKey, rowData?.id, {...actionsState, ...rowData});
    }
    args.cancel = true;
  };

  const toolbarClick = (args) => {
    if (treegrid.current && args.item.text === 'Level 1') {
      treegrid.current.expandAtLevel(0);
      treegrid.current.collapseAtLevel(1);
      treegrid.current.collapseAtLevel(2);
    }
    if (treegrid.current && args.item.text === 'Level 2') {
      treegrid.current.expandAtLevel(0);
      treegrid.current.expandAtLevel(1);
      treegrid.current.collapseAtLevel(2);
    }
    if (treegrid.current && args.item.text === 'Level 3') {
      treegrid.current.expandAtLevel(0);
      treegrid.current.expandAtLevel(1);
      treegrid.current.expandAtLevel(2);
    }
  };

  let initial = true;

  const dataBound = () => {
    if (treegrid.current && initial === true) {
      if (navData.widgetData.level === '1') {
        treegrid.current.expandAtLevel(0);
      }
      if (navData.widgetData.level === '2') {
        treegrid.current.expandAtLevel(0);
        treegrid.current.expandAtLevel(1);
      }
      if (navData.widgetData.level === '3') {
        treegrid.current.expandAtLevel(0);
        treegrid.current.expandAtLevel(1);
        treegrid.current.expandAtLevel(2);
      }
      initial = false;
    }
  };

  const footerItemCounter = (args) => {
    return <span>Items: {args.count}</span>;
  };

  if(!treeGridData){
    return null;
  }

  return (
    <div className='widget-container'>
      <WidgetProvider value={treegrid.current}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {navData.widgetData.label !== '' ? (
          <h3 className='widget-label'>{t(navData.widgetData.label)} </h3>
        ) : (
          ''
        )}
        <div className='widget-content' id='syncfusionTreeGrid'>
          <TreeGridComponent
            locale={JSON.parse(localStorage.getItem('language'))}
            key={`treegrid-${navData.widgetData.id}`}
            height={'100%'}
            rowHeight={28}
            ref={treegrid}
            dataSource={treeGridData.data}
            dataBound={dataBound}
            idMapping={treeGridData?.options?.id}
            parentIdMapping={treeGridData?.options?.parentId}
            pageSettings={pageOptions}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            allowSorting={true}
            allowFiltering={true}
            filterSettings={filterSettings}
            allowResizing={true}
            allowSelection={true}
            enableCollapseAll={navData.widgetData.level === 0 ? true : false}
            treeColumnIndex={1}
            toolbarClick={toolbarClick}
            rowSelecting={rowSelecting}
          >
            <ColumnsDirective>
              {navData.widgetData.columns.map((column) => {
                return (
                  <ColumnDirective
                    key={`field-${column.id}`}
                    field={column.name}
                    headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                    filter={filter}
                    visible={column.hide === false ? true : false}
                    disableHtmlEncode={false}
                    width={column.width}
                    minWidth={column.width}
                    // maxWidth={column.maxWidth}
                  />
                );
              })}
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective showChildSummary={false}>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective columnName='ID' type='count' footerTemplate={footerItemCounter} />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, RowDD, Toolbar, CommandColumn, Aggregate]} />
          </TreeGridComponent>
        </div>
      </WidgetProvider>
    </div>
  );
};

TreeGridWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default TreeGridWidget;
