import React from 'react';
import PropTypes from 'prop-types';
import StringField from './field.string';

const URLField = ({ field, isLocked, value, hiddenClass, setNewValue }) => {

  const generateURL = (input) => (input.startsWith('www.') ? `http://${input}` : input);

  const isURL = (str) => {
    try {
      new URL(generateURL(str));
      return true;
    } catch (error) {
      return false;
    }
  };

  const renderURL = () => (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{field.label}</label>
      <div className='form-input-element url-field'>
        <span className="e-input-group e-control-wrapper e-valid-input e-disabled link-container-disabled">
          <a href={generateURL(value)} target='_blank' rel="noopener noreferrer">{value}</a>
        </span>
      </div>
    </div>
  );

  const renderStringField = () => (
    <StringField
      field={field}
      isLocked={isLocked}
      value={value}
      hiddenClass={hiddenClass}
      setNewValue={setNewValue}
    />
  );

  return isURL(value) && isLocked ? renderURL() : renderStringField();
};

URLField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any
};

export default URLField;
