import React, { FC, useRef, useEffect, useState } from 'react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { debounce } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { withTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

const SelectField: FC<any> = ({ field, isLocked, hiddenClass, options, t, setNewValue }) => {
  const allowMultiSelection = field.type === 'multiSelect';
  const dropdownInstanceRef = useRef(null);
  const [selectOptions, setSelectOptions] = useState(options);
  const data = options;

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (!isLocked) {
      setSelectedValue(fillValueToSelects(options));
    }
  }, [isLocked, options]);

  const onFiltering: any = debounce((e) => {
    let query = new Query();
    query = (e.text !== '') ? query.where('name', 'startswith', e.text, true) : query;
    e.updateData(data, query);
  }, 400);

  const onSelect = (args) => {
    const { itemData } = args;
    const { id } = itemData;

    const updatedOptions = selectOptions.map(option => ({
      ...option,
      selected: allowMultiSelection ? option.id === id ? !option.selected : option.selected : option.id === id,
    }));

    if (!allowMultiSelection) {
      dropdownInstanceRef.current.clear();
    }

    new Promise(resolve => {
      setSelectOptions(updatedOptions);
      resolve(true);
    }).then(() => {
      setNewValue(updatedOptions);
    });
  };

  const onRemoved = (args) => {
    const { itemData } = args;
    const { id } = itemData;

    const updatedOptions = selectOptions.map(option => ({
      ...option,
      selected: allowMultiSelection ? option.selected && option.id !== id : false,
    }));

    new Promise(resolve => {
      setSelectOptions(updatedOptions);
      resolve(true);
    }).then(() => {
      setNewValue(updatedOptions);
    });
  };

  const renderPlaceholder = () => {
    if (options) {
      const selectedOption = options.find(o => o.selected === true);
      if (selectedOption) {
        return selectedOption.label;
      } else if (options.length === 1) {
        return options[0].label;
      } else if (!isLocked) {
        return 'Select something';
      }
    }
    return null;
  };

  const fillValueToSelects = (dataArray) => {
    if (!Array.isArray(dataArray)) {
      return null;
    }
    return dataArray.filter(obj => obj.selected).map(obj => obj.id ?? 0);
  };

  const itemTemplate = (props) => (
    <div className="multiselect-item" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.label) }} />
  );

  if (options) {
    options.forEach(option => {
      option.label = option.label ?? option.name;
      option.id = option.id ?? option.name;
    });
  }

  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
      <div className='form-input-element control-styles'>
        <MultiSelectComponent
          dataSource={data}
          ref={dropdownInstanceRef}
          className='form-input-element'
          placeholder={renderPlaceholder()}
          value={isLocked ? selectedValue : fillValueToSelects(options)}
          select={onSelect}
          removed={onRemoved}
          enabled={!isLocked}
          filtering={onFiltering}
          allowFiltering={true}
          fields={{ text: 'label', value: 'id' }}
          itemTemplate={itemTemplate}
          mode='Box'
          name={field.name}
          data-msg-containerid={`error-${field.attribute.key}`}
        />
      </div>
      <div id={`error-${field.attribute.key}`} />
    </div>
  );
};

export default withTranslation()(SelectField);
