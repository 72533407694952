import axios from 'axios';
import { notifyDebugError } from '../../components/Toasts/toast.debugError';
import { verifySessionToken } from '../services/tools/service.verifySession';
import { notifyWarning } from '../../components/Toasts/toast.warn';

export const runDynamicQuery = async (params: any, widgetData: any) => {

  await verifySessionToken(localStorage.getItem('session'))
    .then((response) => {
      if (response.expired) {
        notifyWarning(response.message);
        localStorage.removeItem('session');
        localStorage.setItem('expired', JSON.stringify(true));
        window.location.reload();
      }
    });

  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}services/widgets/data`,
    {
      data: JSON.stringify({
        params

      }),
      session: localStorage.getItem('session')
    },
  );

  if (res.data.data.error && res.data.data.message) {
    notifyDebugError(res.data.data.message, 'dynamic query');
  }

  // ToDo: check response in API
  const {action, key, log, widgetKey, timestamp, ..._params } = params;
  _params.params = {action, key, log, widgetKey, timestamp};
  delete _params.params;

  console.log(
    `%c ${widgetData.widget} (key: ${widgetData.key}) %c `,
    `background:${res.data.data.error ? '#FF3333' : '#FF8800'}; color: #000`,
    'background: transparent; color: black',
    {
      params: _params,
      response: res.data.data,
      widgetData: widgetData
    }
  );
  return res.data.data;

};