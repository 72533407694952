import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { callFunctions } from '../../api/functions/call.functions';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { notifyError } from '../Toasts/toast.error';
import PropTypes from 'prop-types';
import store from '../../store/store';

const MenuBarDropdownButton: any = ({ element, menuIndex, widget }) => {
  const { t } = useTranslation();
  const [isOpenIconClass, setIsOpenIconClass] = useState('e-chevron-right');

  const handleDropdownButtonClick = async (args) => {
    const key = args.item?.key;

    if (!key) {
      notifyError('Event is missing');
      return null;
    }

    let data;
    let files;


    switch (widget.widgetData.widget) {
    case 'Diagram':
      data = await widget.getContent();
      break;
    case 'View':
      break;
    case 'Gantt':
      data = await widget.getCurrentGanttData();
      break;
    case 'Grid':
      data = widget.state.gridEditData;
      break;
    case 'TreeGrid':
    case 'Graph':
      runActions(key, widget.widgetData.dataID, { ...widget.actionsState })
        .then(async() => {
          const functions = store.getState().workspaces.instances.find(instance => instance.isActive).data?.functions;
          if (functions) {
            await callFunctions(functions, { reloadLayout: widget.reloadLayout });
          }
        });
      break;
    case 'Form':
      if (widget.validationFormObject?.validate() === false) {
        return null;
      }
      data = widget.formEditData;
      files = widget.formEditFiles;
      break;
    default:
      return null;
    }

    return runActions(key, widget.widgetData.dataID, { ...widget.actionsState, data: data }, files);
  };


  const handleClose = () => {
    setIsOpenIconClass('e-chevron-right');
  };

  const handleOpen = () => {
    setIsOpenIconClass('e-chevron-down');
  };



  return (
    <DropDownButtonComponent
      items={element.elements.sort((a, b) => (a.sequence - b.sequence)).map((subMenuItem) => {
        return {
          text: t(subMenuItem.label),
          iconCss: 'e-ddb-icons e-dashboard',
          id: subMenuItem.key,
          key: subMenuItem.events.find(event => event.type === 'onClick')?.key
        };
      })}
      iconPosition="Left"
      cssClass='e-custom-dropdown'
      select={handleDropdownButtonClick}
      close={handleClose}
      open={handleOpen}
      key={'Dropdown' + menuIndex}
    >
      {t(element.label)}
      <span className={`e-icons e-small ${isOpenIconClass} dropdown-icon`}></span>
    </DropDownButtonComponent>
  );
};

MenuBarDropdownButton.propTypes = {
  element: PropTypes.object,
  menuIndex: PropTypes.number,
  widget: PropTypes.object
};

export default MenuBarDropdownButton;
