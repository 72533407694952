import store from '../../store';
import { runWorkflow } from '../../../api/workflow/runWorkflow';
import { notifyWarning } from '../../../components/Toasts/toast.warn';
import { setWorkspacePending, updateWorkspaceActions } from './actions';
import { fetchWidgets } from '../widgets/actions';
import { closeMultiUpload } from '../modals/actions';
import { notifyError } from '../../../components/Toasts/toast.error';

export const run = async (key: number, id: number, params: any = {}, files: any = null) => {
  try {
    if (!key) {
      notifyWarning('Event is missing');
      return null;
    }

    store.dispatch(setWorkspacePending(true));

    const response = await runWorkflow(key, id, params, files, false);
    
    store.dispatch(setWorkspacePending(false));
    
    if (!response) {
      return null;
    }

    store.dispatch(closeMultiUpload({}));
    
    fetchWidgets(
      response.key,
      response.id
    );
    await store.dispatch(updateWorkspaceActions({...response.params, functions: response.functions, timestamp: response.timestamp}));

    return response;
  } catch (error) {
    notifyError(`run error: ${error}`);
    throw error;
  }
};
