import types from '../types';
import { getModules as getModulesNew_API } from '../../../api/query/static/run.modules';
import { logger } from '../../../utils/logger';

export const getModules = () => async (dispatch) => {
  try {
    const response = await getModulesNew_API();
    dispatch({ 
      type:  types.MODULES_SET, 
      data: response 
    });
  } catch (err) {
    logger.error(`Get modules error (${types.MODULES_SET}): `, err);
  }
};

export const setSelectedModule = (key) => ({
  type: types.MODULES_SET_SELECTED,
  key
});

