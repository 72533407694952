import { toast } from 'react-toastify';
import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const notifyError = (toastContent, error?) => {
  toast.error(
    `${toastContent + error?.message != null ? `\n${error?.message}` : '' }`,
    {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      style: {whiteSpace: 'pre-line'}
    }
  );
};