import React, { useRef } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const StringField = ({ field, isLocked, value, hiddenClass, setNewValue }) => {
  const textboxRef = useRef(null);
  const { t } = useTranslation();

  const onChange = (args) => {
    setNewValue(args.value);
  };

  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
      <div className='form-input-element '>
        <TextBoxComponent
          id={`attribute-${field.attribute.key}`}
          ref={textboxRef}
          className='form-input-element'
          value={value}
          input={onChange}
          enabled={!isLocked}
          type="text"
          name={field.name}
          data-msg-containerid={`error-${field.attribute.key}`}
        />
      </div>
      <div id={`error-${field.attribute.key}`} />
    </div>
  );
};

StringField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any
};

export default StringField;
