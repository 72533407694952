import React, { useEffect, useState, useRef } from 'react';
import Handlebars from 'handlebars';
import { marked } from 'marked';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { service as linkService } from './service/linkService';
import { HtmlEditor, Inject, RichTextEditorComponent, Resize, MarkdownEditor } from '@syncfusion/ej2-react-richtexteditor';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { WidgetProvider } from '../../WidgetContext';
import PropTypes from 'prop-types';

const View = ({ navData, actionsState }) => {
  const [content, setContent] = useState('');
  const viewInstance = useRef(null);

  const widgetContext = {
    widgetData: navData.widgetData,
    viewInstance: viewInstance.current,
    actionsState: actionsState
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
  
        switch (response.type) {
        case 'HTML': {
          const template = Handlebars.compile(response?.content ?? ' ');
          const result = template(response?.data);
          setContent(result);
          break;
        }
        case 'Markdown':
          setContent(response.content ? marked(response.content) : ' ');
          break;
        default:
          setContent(response?.content ?? ' ');
          break;
        }
  
        setClickEventLink();
      } catch (error) {
        console.log(`error fetch view data: ${error}`);
      }
    };
  
    fetchData();
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);
  



  const setClickEventLink = () => {
    const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    let uuid;

    const eventKey = navData.widgetData.events?.find((event) => event?.type === 'onClick')?.key;
    setTimeout(() => {
      document.querySelectorAll('a').forEach((element) => {
        if (element.classList.contains('disable-link')) {
          element.classList.remove('disable-link');
        }

        if (element.href) {
          element.addEventListener('click', (event) => {
            event.preventDefault();
            const url = new URL(element.href);
            uuid = url.pathname.slice(1);
            if (regexExpUUID.test(uuid)) {
              event.preventDefault();
              const params = {
                uuid: uuid
              };
              linkService('system', params)
                .then((response) => {
                  runActions(eventKey, response.id, null);
                })
                .catch((error) => console.log(error));
            } else {
              window.open(url.href, '_blank');
              return null;
            }
          });
        }
      });
    }, 500);
  };

  if (!content) return null;

  return (
    <div className='widget-container'>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.menu ? (
          <MenuBar 
            key={`menu-${navData.widgetData.menu.id}`} 
            menu={navData.widgetData.menu}
          />
        ) : null}
        {/* <h3 className='widget-label'>{t(navData.label)} </h3> */}
        <RichTextEditorComponent
          readonly={true}
          height={'calc(100% - 50px)'}
          ref={(richtexteditor) => {
            viewInstance.current = richtexteditor;
          }}
          value={content}
          editorMode={'HTML'}
        >
          <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
        </RichTextEditorComponent>
        <style>{navData.widgetData.code}</style>
      </WidgetProvider>
    </div>
  );
};


View.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default View;
