import axios from 'axios';
import { callFunctions } from './../functions/call.functions';
import { notifyMessage } from './../utils/notify';
import { verifySessionToken } from './../services/tools/service.verifySession';
import { notifyWarning } from '../../components/Toasts/toast.warn';
import { notifyError } from '../../components/Toasts/toast.error';

export const runWorkflow = async (key: any, id: any, params: any, file: any, newTab: boolean) => {

  try {
    await verifySessionToken(localStorage.getItem('session'))
      .then((response) => {
        if (response.expired) {
          notifyWarning(response.message);
          localStorage.removeItem('session');
          localStorage.setItem('expired', JSON.stringify(true));
          window.location.reload();
        }
      });
  
  
    const formData = new FormData();
    formData.append('key', key);
    formData.append('id', id);
    formData.append('params', JSON.stringify(params));
    formData.append('file', file);
    formData.append('session', localStorage.getItem('session'));
  
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}services/workflow`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.data.functions && !newTab) {
      callFunctions(response.data.functions, null);
    }
    const error = false;
    response?.data.debug?.forEach(
      debug => {
        notifyMessage(debug);
      }
    );
    return !error ? response.data : null;
  } catch (error) {
    notifyError('runWorkflow error:', error);
    throw error;
  }
};