import React, { FC, useEffect, useState } from 'react';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import notAvailablePic from '../../images/missing.png';
import { serviceFilePreview } from '../../api/services/tools/service.filePreview';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';

const CarouselWidget: FC<any> = ({ actionsState, navData }) => {
  const [imageData, setImageData] = useState(null);
  const onClickEventKey = navData.widgetData.events.find(event => event.type === 'onClick')?.key ?? null;

  // useEffect(() => {
  //   fetchData();
  // }, [actionsState.key]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (navData) {
      try {
        const response = await runDynamicQuery({...actionsState , widgetKey: navData.widgetData.key}, navData.widgetData);
        const newTargetsArray = await Promise.all(response.targets.map(async (image) => {
          const filePreviewImage = await serviceFilePreview('/services/file/preview?absolutePath=' + image.image);
          const filePreviewThumbnail = await serviceFilePreview('/services/file/preview?absolutePath=' + image.thumbnail);
        
          const isImagePreviewValid = filePreviewImage && !filePreviewImage.error;
          const fileType = isImagePreviewValid ? matchFileType(image.image) : null;
        
          image.original = isImagePreviewValid
            ? `data:@file/${fileType};base64,${filePreviewImage.data}`
            : notAvailablePic;
        
          image.thumbnail = isImagePreviewValid
            ? `data:@file/${fileType};base64,${filePreviewThumbnail.data}`
            : notAvailablePic;
          image.description = null;
        
          return image;
        }));


        setImageData(newTargetsArray);
        if(onClickEventKey){
          runActions(onClickEventKey, newTargetsArray[0]?.id, actionsState);
        }
      } catch (error) {
        console.log('Error fetchData carousel: ', error);
      }
    }
  };

  const matchFileType = (stringValue) => {
    if (stringValue) {
      const check_png = stringValue.toLowerCase().includes('.png');
      const check_jpg = stringValue.toLowerCase().includes('.jpg');
      const check_jpeg = stringValue.toLowerCase().includes('.jpeg');
      const check_gif = stringValue.toLowerCase().includes('.gif');
      switch (true) {
      case check_png:
        return 'png';
      case check_jpg:
        return 'jpg';
      case check_jpeg:
        return 'jpeg';
      case check_gif:
        return 'gif';
      }
    }
  };

  const onSlide = (index) => {
    if (onClickEventKey !== null) {
      runActions(onClickEventKey, imageData[index]?.id, actionsState);
    }
  };

  if (!imageData) {
    return null;
  }

  return (
    <div className="widget-container">
      <ImageGallery 
        items={imageData} 
        onSlide={onSlide}
        showPlayButton={false}
        disableThumbnailScroll={false}
        lazyLoad={true}
      />
    </div>
  );
};

CarouselWidget.propTypes = {
  actionsState: PropTypes.object,
  navData: PropTypes.object,
  props: PropTypes.object,
  index: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  absolutePath: PropTypes.string,
  currentIndex: PropTypes.number,
};

export default CarouselWidget;
