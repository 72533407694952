import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import PageItem from './breadcrumb.section';

const BreadcrumbCustom = () => {
  const workspaces = useSelector((state: any) => state.workspaces);

  const goHome = () => { window.location.reload(); };

  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        onClick={goHome}
      >
        <div className='breadcrumb-item'>
          <div className={'e-icons e-home breadcrumb-icon'} />
          <div className='breadcrumb-label'>Home</div>
          <Breadcrumb.Divider icon="right chevron" />
        </div>
      </Breadcrumb.Section>
      {workspaces.instances.find(instance => instance.isActive)?.breadcrumb.shiftHelper?.length >= 1 ?
        <>
          <Breadcrumb.Section> ... </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
        </>
        : null
      }

      {
        workspaces.instances.find(instance => instance.isActive)?.breadcrumb.historyPages?.map((params, itemIndex) => {
          return <PageItem
            key={`page-item-${params.key} + random():${Math.random()}`}
            itemIndex={itemIndex}
            params={params}
          />;
        })
      }
    </Breadcrumb>
  );
};

export default BreadcrumbCustom;