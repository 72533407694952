import { notifyError } from '../../../components/Toasts/toast.error';

export const sendPost = async (message: any) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}services/messenger/send-post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
  } catch (error) {
    if(error.statusCode === 404) {
      notifyError('webpage does not exist');
    } else {
      notifyError('Error sending message}', error);
      throw error;
    }
  }
};
