import axios from 'axios';
import { notifyDebugError } from '../../components/Toasts/toast.debugError';
import { verifySessionToken } from '../services/tools/service.verifySession';
import { notifyWarning } from '../../components/Toasts/toast.warn';
import { notifyMessage } from '../utils/notify';

export const runStaticQuery = async (queryAlias: any, params: any) => {

  await verifySessionToken(localStorage.getItem('session'))
    .then((response) => {
      if (response.expired) {
        notifyWarning(response.message);
        localStorage.removeItem('session');
        localStorage.setItem('expired', JSON.stringify(true));
        window.location.reload();
      }
    });

  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}services/queries`,
    {
      data:
        JSON.stringify({
          params,
          queryAlias
        }),
      session: localStorage.getItem('session')
    }
  );

  response?.data.debug?.forEach(
    debug => {
      notifyMessage(debug);
    }
  );

  if (response.data.data.error && response.data.data.message) {
    notifyDebugError(response.data.data.message, queryAlias);
    // console.log(
    //   `%c Static: ${queryAlias} %c`,
    //   'background: #f33; color: #FFF',
    //   'background: transparent; color: #000',
    //   {
    //     params,
    //     response: response.data.data
    //   }
    // );
  }

  return response.data.data;

};