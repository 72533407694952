import { notifyError } from '../../../../components/Toasts/toast.error';
import { run as runActions } from '../../../../store/workspaces/workspaces/run';

export const cellSelecting = async (args, navData, globalKeyEvent, workspaceActions) => {

  const rowData: any = args;
  if (rowData.currentCell.classList.contains('code-cell')) return null;
  const eventKey = navData.widgetData?.events?.find((event) => event.type === 'onClick')?.key;

  window.dispatchEvent(
    new CustomEvent(
      'custom-mousedown',
      {
        detail: { 
          key: eventKey,
          id: rowData?.data?.id, 
          event: { ctrlKey: args.isCtrlPressed },
          context: 'grid'
        }
      }
    )
  );

  // await interactionContext.handleMouseDown(
  //   { ctrlKey: true, button: 0 },
  //   { id: rowData?.data?.id }
  // );

  if (!eventKey) {
    notifyError('an event is missing');
    return null;
  }

  if (args.isShiftPressed) {
    const currentCell: any = rowData.currentCell;
    navigator.clipboard.writeText(currentCell.innerText);
    return null;
  }

  if (!args.isCtrlPressed) {
    runActions(eventKey, rowData?.data.id, { ...workspaceActions, ...rowData?.data ?? {} });
  }
  args.cancel = true;
};