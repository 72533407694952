/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import Routes from './routes/router';
import { registerLicense } from '@syncfusion/ej2-base';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { getSyncfusionLicense } from './api/services/tools/service.syncfusionLicense';
import './i18n';
import 'semantic-ui-css/semantic.min.css';

import store from './store/store';


import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-calendars/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css';
import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-treegrid/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';
import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-kanban/styles/material.css';
import InteractionProvider from './interactions/InteractionProvider';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

console.warn = () => {};

const Main = () => (
  <InteractionProvider>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </I18nextProvider>
  </InteractionProvider>
);

getSyncfusionLicense()
  .then((response) => {
    registerLicense(response.data);
  })
  .then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    document.getElementById('main-html').setAttribute('lang', navigator.language || navigator.userLanguage);
    root.render(<Main />);
    registerServiceWorker();
  });

