import React, { useState, useEffect, useRef } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { getData } from './services/getDataDiagram';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DiagramGrid = ({ setSelectedElement, tabType, params }) => {
  const [gridData, setGridData] = useState(null);
  const gridInstance = useRef(null);
  const { t } = useTranslation();

  const gridRenderData = {
    columns: [
      { name: 'target.ID', label: 'ID', field: 'target.ID', hide: false, minWidth: 50 },
      { name: 'id', label: 'id', field: 'id', hide: true, minWidth: 50 },
      { name: 'name', label: 'name', field: 'name', hide: false, minWidth: 150 },
      { name: 'image', label: 'image', field: 'image', hide: false, minWidth: 50 },
      { name: 'template', label: 'template', field: 'template', hide: false, minWidth: 100 },
      { name: 'preview', label: 'preview', field: 'preview', hide: true }
    ],
    rowSelection: 'single',
    type: 'link'
  };

  useEffect(() => {
    getData(setGridData, params, tabType);
  }, []);

  useEffect(() => {
    if (tabType !== 'new') {
      getData(setGridData, params, tabType);
    }
  }, [tabType]);

  const rowSelected = (args) => {
    setSelectedElement(args.data);
  };

  const rowSelecting = () => {
    gridInstance.current.clearSelection();
  };

  if (!gridData) return null;

  return (
    <div className='widget-container'>
      <div className="widget-content">
        <GridComponent
          ref={gridInstance}
          height={'550px'}
          rowHeight={28}
          className={' e-link-grid'}
          dataSource={gridData}
          allowFiltering={true}
          allowSelection={true}
          rowSelected={rowSelected}
          rowSelecting={rowSelecting}
        >
          <ColumnsDirective>
            <ColumnDirective
              type='checkbox'
              width='24px'
              allowResizing={false}
            />
            {gridRenderData.columns.map((column: any) => {
              if (column.type === 'boolean') return null;

              return (
                <ColumnDirective
                  key={`field-${column.id}`}
                  headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                  width={column.minWidth}
                  minWidth={column.minWidth}
                  maxWidth={column.maxWidth}
                  textAlign={column.type === 'code' ? 'Center' : undefined}
                  field={column.name}
                  visible={!column.hide}
                  disableHtmlEncode={false}
                  isPrimaryKey={column.name === 'id'}
                  type={column.type === 'code' ? 'string' : undefined}
                />
              );
            })}
          </ColumnsDirective>
        </GridComponent>
      </div>
    </div>
  );
};

DiagramGrid.propTypes = {
  tabType: PropTypes.string,
  setSelectedElement: PropTypes.func,
  params: PropTypes.any
};

export default connect()(DiagramGrid);
