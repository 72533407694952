/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { jwtDecrypt } from '../../../api/services/tools/service.jwtDecrypt';

const DateField = ({ field, isLocked, value, hiddenClass, setNewValue }: any) => {
  const [dateFormat, setDateFormat] = useState('');
  const [date, setDate] = useState(null);
  const datepickerInstance = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
        const dateFormat = response.data.user.dateFormat ?? '';
        setDateFormat(dateFormat);
      } catch (error) {
        console.log(`jwtDecrypt error: ${error}`);
      }
    };
    fetchData();
    setDate(value ? new Date(value): null);
  }, []);

  useEffect(() => {
    setNewValue(date ? new Date(date).toISOString(): null);
  }, [date]);


  return (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <span className='validation-label' id={`validation-label-${field.attribute.key}`}></span>
      <div className='form-input-element'>
        <DatePickerComponent
          ref={datepickerInstance}
          className='form-input-element'
          name="date"
          value={date}
          change={(args) => setDate(args.value)}
          enabled={!isLocked}
          format={dateFormat}
        />
      </div>
    </div>
  );
};

DateField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any
};


export default DateField;
