import * as React from 'react';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import history from './history';
import LoginPage from '../pages/login/page.login';
import ModulePage from '../pages/module/page.module';
import NotFoundPage from '../pages/notFound/page.notFound';
import { getThemes } from '../store/getThemes/action';
import { run as runActions } from '../store/workspaces/workspaces/run';
import { jwtDecrypt } from '../api/services/tools/service.jwtDecrypt';
import store from '../store/store';
import { verifySessionToken } from '../api/services/tools/service.verifySession';
import { notifyWarning } from '../components/Toasts/toast.warn';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { runStaticQuery } from '../api/query/run.staticQuery';
import { notifyError } from '../components/Toasts/toast.error';

const RoutesCostum: React.FC<any> = () => {

  verifySessionToken(localStorage.getItem('session'))
    .then((response) => {
      if (response.expired) {
        notifyWarning(response.message);
        localStorage.clear();
        localStorage.setItem('expired', JSON.stringify(true));
        window.location.reload();
      }
    });

  const getSessionToken = () => {
    let connectTrue = true;

    if (localStorage.getItem('session') === null || localStorage.getItem('session') === undefined)
      connectTrue = false;

    return connectTrue;
  };
  


  const jwtString = window.location.pathname.split('app/')[1];

  useEffect(() => {
    const fetchWorkspaceData = async () => {
      const response = await runStaticQuery('workspace', null);
      const onLoadEvent = response.workspace.events.find((event) => event?.type === 'onLoad');

      if (!jwtString) {
        await runActions(onLoadEvent.key, null, null);
      }
    };

    fetchWorkspaceData();
  }, []);

  const ProtectedRoute = ({ children }) => {

    useEffect(() => {
      const fetchData = async () => {
        if (jwtString) {
          try {
            const response = await jwtDecrypt(jwtString);

            runActions(response.data.key, response.data.id, response.data);
          } catch (error) {
            notifyError('Error fetching data:', error);
            throw error;
          }
        }
      };

      fetchData();
    }, [jwtString]);


    if (!getSessionToken()) {
      return <Navigate to='/login' />;
    } else {
      store.dispatch<any>(getThemes());
      return children;
    }
  };

  ProtectedRoute.propTypes = {
    children: PropTypes.any
  };


  return (
    <BrowserRouter >
      <Routes>
        <Route path="/login" element={<LoginPage history={history} />} />
        {/* <Route path="/request-password" element={<RequestPasswordPage />} />
        <Route path="/reset-password" element={<SetNewPasswordPage />} /> */}
        <Route path="/" element={
          <ProtectedRoute>
            <Navigate to='/app' />
          </ProtectedRoute>}
        />
        <Route path="/app" element={
          <ProtectedRoute>
            <ModulePage />
          </ProtectedRoute>}
        />
        <Route path="/app/:jwtString" element={
          <ProtectedRoute>
            <Navigate to='/app' />
          </ProtectedRoute>}
        />
        <Route path="/404" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};


export default connect(null,
)(RoutesCostum);
