import React, { useState, useEffect } from 'react';
import Card from './Gallery.card';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import PropTypes from 'prop-types';
import './styles.css';
import { notifyError } from '../../components/Toasts/toast.error';

const GalleryWidget = ({ navData, actionsState }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ widgetKey: navData.widgetData.key, ...actionsState }, navData.widgetData);
        setImageData(response.targets);
      } catch (error) {
        notifyError('Error fetching gallery data:', error);
      }
    };

    if (navData && actionsState?.data) {
      fetchData();
    }
  }, [navData.widgetData.dataID, actionsState.key, actionsState.timestamp ]);


  const handleClick = async (imageID) => {
    runActions(navData.widgetData.events.find((event) => event.type === 'onClick').key, imageID, actionsState);
  };

  const setCounter = () => `(${imageData.length} ${imageData.length === 1 ? 'item' : 'items'})`;

  if (!imageData) {
    return null;
  }

  return (
    <>
      <div className="gallery">
        <div className="gallery-container wrap">
          {imageData.map((image) => (
            <div key={`gallery-card-wrapper-${image.id}`} className="gallery-item" onClick={() => handleClick(image.id)}>
              <Card
                key={`gallery-item-${image.id}`}
                absolutePath={image.absolutePath}
                imageName={image.name}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='gallery-counter'>
        {setCounter()}
      </div>
    </>
  );
};

GalleryWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GalleryWidget;
