import * as React from 'react';
import { ColorPickerComponent, ColorPickerEventArgs } from '@syncfusion/ej2-react-inputs';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ColorPickerField = ({ field, value, hiddenClass, setNewValue}) => {
  const colorPickerRef = useRef(null);
  const { t } = useTranslation();

  const onChange = (args: ColorPickerEventArgs) => {
    setNewValue(args.currentValue.hex);
  };

  return (
    <div className={`FormInputContainer ${hiddenClass}`}>
      <label className='form-input-label'>{t(field.label)}</label>
      <div className='FormInputElement '>
        <ColorPickerComponent
          ref={colorPickerRef}
          id='color-picker'
          value={value}
          change={onChange}
          style={{ marginTop: '20px' }}
        />
      </div>
    </div>
  );
};

ColorPickerField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  hiddenClass: PropTypes.any
};

export default ColorPickerField;
