import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { notifyError } from '../components/Toasts/toast.error';

type RequestMethod = 'DELETE' | 'GET' | 'HEAD' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT' | 'TRACE';


export default async function request<RequestType extends Record<string, any>, ResponseType extends Record<string, any> = Record<string, any>>(
  method: RequestMethod,
  endpoint: string,
  parameters: RequestType = {} as RequestType,
  headers?: any,
): Promise<ResponseType> {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  if (!baseUrl) {
    throw new Error('API_BASE_URL is not defined in your .env file.');
  }

  const hasBody = method === 'PATCH'
    || method === 'POST'
    || method === 'PUT';


  const fullEndpoint = baseUrl + endpoint;

  const config: AxiosRequestConfig = {
    method,
    url: fullEndpoint,
    params: hasBody ? undefined : parameters,
    data: hasBody ? parameters : undefined,
    headers,

  };

  try {
    const response: AxiosResponse<ResponseType> = await axios(config);
    return response.data;
  } catch (error) {
    notifyError(`${endpoint}, ${error.response.data.message}`);
    throw new Error(error);
  }
}

