import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import notAvailablePic from '../../../images/missing.png';
import { serviceFilePreview } from '../../../api/services/tools/service.filePreview';

const Base64Field: FC<any> = ({ field, value, isHidden }) => {
  const [imageBase64, setImageBase64] = useState(null);
  const { t } = useTranslation();

  const isImage = ![null, undefined].includes(value) ? value.toLowerCase().split('.').pop().match(/^(png|jpg|jpeg|gif|svg)$/) : false;

  useEffect(
    () => fetchBase64Image(),
    [value]
  );

  const fetchBase64Image = () => {
    if (isImage) {
      serviceFilePreview(value)
        .then((response: any) => {
          //setImageBase64('data:@file/' + (matchFileType(value)) + ';base64,' + response.data)
          response === undefined || response.error ? setImageBase64(notAvailablePic) : setImageBase64(`data:${response.type};base64,${response.data}`);
        })
        .catch(() => null);
    }
  };

  if (isImage) {
    return (
      <div className='form-input-container' style={{ display: isHidden }}>
        <label className='form-input-label'>{t(field.label)}</label>
        {
          imageBase64 ?
            (<img src={imageBase64} alt="Preview File" width={field.height} height={field.height} />)
            : null
        }
      </div>
    );
  } else {
    return (
      <div className='form-input-container'>
        <label className='form-input-label'>{t(field.label)}</label>
        <div>Preview nicht vorhanden</div>
      </div>
    );
  }
};

export default Base64Field;