import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import HeaderMenu from './Header.menu';
import BreadcrumbCustom from './breadcrumb/breadcrumb';
import NotificationMessage from '../errorBoundary/errorBoundary';
import './styles.css';

const Header = () => {
  return (
    <Grid className='header-container' columns={2} divided>
      <Grid.Row className='header-content'>
        <Grid.Column width={13} style={{ boxShadow: 'none' }}>
          <Grid.Row>
            <NotificationMessage>
              <BreadcrumbCustom />
            </NotificationMessage>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={3} style={{ width: '250px', boxShadow: 'none' }} floated={'right'} textAlign={'right'}>
          <NotificationMessage>
            <HeaderMenu />
          </NotificationMessage>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default connect()(Header);
