import { notifyWarning } from '../../../components/Toasts/toast.warn';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { v4 as uuidv4 } from 'uuid';

export const openWorkspaceReduce = (state, action) => {

  const { instances } = state;
  const activeBreadcrumbInstance = instances.find(instance => instance.isActive)?.breadcrumb || { shiftHelper: [], historyPages: [], currentPage: null };
  const activeNavigationInstance = instances.find(instance => instance.isActive).navigation;

  if (instances.length >= 10) {
    notifyWarning('Max. 10 workspaces');
    return state;
  }
  
  const newWorkspace = {
    name: 'workspace',
    uuid: null,
    isOpen: true,
    isNewTab: true,
    isActive: false,
    data: action.params || null,
    pending: false,
    isPinned: false,
    breadcrumb: action.widgetContext !== 'modules'
      ? activeBreadcrumbInstance
      : {
        shiftHelper: [],
        historyPages: [],
        currentPage: null,
      },
    widgets: {},
    modals: {
      imageDialog: {
        isOpen: false,
        params: null
      },
      confirmModal: {
        isOpen: false,
      },
      title: null,
      content: null,
      imageEvent: null,
      linkEvent: null,
      eventType: null,
      selectedText: '',
      multiUpload: {
        isOpen: false,
        widgetData: {}
      },
      dialog: {
        isOpen: false
      }
    },
    chat: {
      isOpen: false,
      id: null,
      visible: true,
      posts: [],
      filterMode: false,
      badge: {
        visible: false,
        content: null
      }
    },
    navigation: activeNavigationInstance
  };
      
  
  return {
    ...state,
    instances: instances.concat(newWorkspace),
  };
};

export const removeWorkspaceReduce = (state, action) => {
  const { instances, activeWorkspace } = state;
  const updatedActiveWorkspace = {
    index: activeWorkspace.index === 0 ? action.index : activeWorkspace.index - 1,
    data: null,
  };
      
  return {
    ...state,
    instances: instances.filter((workspace, index) => index !== action.index),
    activeWorkspace: updatedActiveWorkspace,
  };
};

export const setActiveWorkspaceReduce = (state, action) => {
  const updatedInstances = state.instances.map((instance, index) => {
    return {
      ...instance,
      isActive: action.index === index
    };
  });
  
  return {
    ...state,
    instances: updatedInstances,
    activeWorkspace: {
      index: action.index
    }
  };
};


export const updateWorkspaceReduce = (state, action) => {
  const updatedWorkspaces = state.instances.map((instance) => {
    if (instance.isActive) {
      instance.data = action.newWorkspaceAction;
    }
    return instance;
  });
    
  return {
    ...state,
    instances: updatedWorkspaces,
  };
};


export const setWorkspaceIsNewReduce = (state, action) => {
  const { instances } = state;
  const updatedTabs = [...instances];
  updatedTabs[action.index].isNewTab = action.isNewTab;
    
  return {
    ...state,
    instances: updatedTabs
  };
};

export const renameWorkspaceReduce = (state, action) => {
  const updatedWorkspaces = state.instances.map((instance) => {
    if (instance.isActive) {
      instance.name = action.newName;
    }
    return instance;
  });
    
  return {
    ...state,
    instances: updatedWorkspaces,
  };
};

export const setWorkspacePendingReduce = (state, action) => {
  const updatedWorkspaces = state.instances.map((instance) => {
    if (instance.isActive) {
      instance.isPending = action.pending;
    }
    return instance;
  });
    
  return {
    ...state,
    instances: updatedWorkspaces,
  };
};

export const setWorkspacePinnedReduce = (state, action) => {
  const updatedWorkspaces = state.instances.map((instance, index) => {
    if (index === action.workspaceIndex) {
      instance.uuid = instance.isPinned ? instance.uuid : uuidv4();
      instance.isPinned = !instance.isPinned;
    }
    return instance;
  });
    
  return {
    ...state,
    instances: updatedWorkspaces,
  };
};

export const addPinnedWorkspacesReduce = (state, action) => {
  const workspaces = action.pinnedWorkspaces.map(workspace => ({
    ...workspace,
    isNewTab: true
  }));
  return {
    ...state,
    instances: state.instances.concat(workspaces),
  };
};


