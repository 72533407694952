import React, { useEffect, useRef } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { notifyError } from '../Toasts/toast.error';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MenuBarButton = ({ widget, element, handleNewTab }) => {
  const buttonInstance = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDownEvent = (event) => {
      const isFunctionKey = /^F[1-9]$|^F10$/.test(event.key);
      const isEnterKey = event.key === 'Enter';
      const eventApi = element.events?.find((eventObj) => eventObj?.type === 'onClick');
      
      if (
        eventApi &&
        shortcut &&
        shortcut === event.key &&
        (
          isFunctionKey ||
          isEnterKey
        )
      ) {
        handleKeyDown(event);
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDownEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [widget]);

  const fetchDataForWidget = async () => {
    let data;
    let files;
    switch (widget.widgetData.widget) {
    case 'Diagram':
      data = await widget.getContent();
      break;
    case 'View':
      if (widget.widgetData.type === 'edit') {
        try {
          const instance = widget.viewInstance;
          data = {
            annotations: instance.annotationCollection,
            fields: await Promise.all(instance.formFieldCollection.map(async (field) => ({ ...field.properties, bounds: field.properties.bounds.properties, font: field.properties.font.properties }))),
            signatures: instance.signatureCollection,
          };
          files = await instance.saveAsBlob();
        } catch (error) {
          notifyError('Error exporting form fields and annotations:', error);
          throw error;
        }
      }
      break;
    case 'Gantt':
      data = await widget.getCurrentGanttData();
      break;
    case 'TreeGrid':
    case 'Grid':
      data = widget.gridEditData;
      break;
    case 'Graph':
      data = await widget.getAllNodesAndEdges();
      break;
    case 'Form':
      if (widget.validationFormObject?.validate() === false) {
        return null;
      }
      data = widget.formEditData;
      files = widget.formEditFiles;
      break;
    case 'Scheduler':
      data = await widget.getCurrentViewEvents();
      break;
    default:
      return null;
    }

    return { data, files };
  };

  const handleKeyDown = async (event) => {
    const response = await fetchDataForWidget();
    const eventApi = element.events?.find((eventObj) => eventObj?.type === 'onClick');

    if (eventApi) {
      const { shortcut, key } = eventApi;
      if (!key) {
        return null;
      }
      if (shortcut && shortcut === event.key) {
        buttonInstance.current.focusIn();
        return runActions(key, widget.widgetData.dataID, { ...widget.actionsState, data: response.data }, response.files);
      }
    }
  };

  const handleButtonClick = async (e) => {
    const response = await fetchDataForWidget();
    const eventApi = element.events?.find((eventObj) => eventObj?.type === 'onSubmit' || eventObj?.type === 'onClick');

    if (eventApi) {
      const { key } = eventApi;
      if (!key) {
        notifyError('Event is missing');
        return null;
      }
      if (!e?.ctrlKey) {
        return runActions(key, widget.widgetData.dataID, { ...widget.actionsState, data: response.data }, response.files);
      }
      handleNewTab(key, widget.widgetData.dataID, element.label, e);
    }
  };


  const labelText = t(element.label);
  const shortcut = element.events?.find((eventObj) => eventObj?.type === 'onClick')?.shortcut ?? null;
  const shortcutText = shortcut !== null ? ` [${shortcut}]` : '';


  return (
    <ButtonComponent
      ref={buttonInstance}
      id={`button-${element.key}`}
      cssClass='e-custom'
      style={{ textTransform: 'none' }}
      content={labelText + shortcutText}
      disabled={element.disabled}
      // key={`menu-item-${element.key}-random:${Math.random()}`}
      type={'button'}
      iconCss={element.icon.name !== '' ? `e-icons e-${element.icon.name}` : null}
      onClick={(e) => {
        handleButtonClick(e);
      }}
    />
  );
};

MenuBarButton.propTypes = {
  widget: PropTypes.object,
  element: PropTypes.object,
  handleNewTab: PropTypes.func
};


export default MenuBarButton;
