import React from 'react';
import PropTypes from 'prop-types';
import StringField from './field.string';

const EmailField = ({ field, isLocked, value, hiddenClass, setNewValue }) => {

  const isEmail = (str) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);

  const renderEmailField = () => (
    <div className={`form-input-container ${hiddenClass}`}>
      <label className='form-input-label'>{field.label}</label>
      <div className='form-input-element email-field'>
        <span className="e-input-group e-control-wrapper e-valid-input link-container-disabled">
          <a href={`mailto:${value}`}>{value}</a>
        </span>
      </div>
    </div>
  );

  const renderStringField = () => (
    <StringField
      field={field}
      isLocked={isLocked}
      value={value}
      hiddenClass={hiddenClass}
      setNewValue={setNewValue}
    />
  );

  return isEmail(value) && isLocked ? renderEmailField() : renderStringField();
};

EmailField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any
};

export default EmailField;
