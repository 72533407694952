import React, { FC, useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';

import '@syncfusion/ej2-layouts/styles/material.css';
import notAvailablePic from '../../images/missing.png';
import { serviceFilePreview } from '../../api/services/tools/service.filePreview';

const ImageCard: FC<any> = ({ absolutePath, imageName }) => {
  const [imageBase64, setImageBase64] = useState(null);
  const isImage = ![null, undefined].includes(absolutePath) ? absolutePath.toLowerCase().split('.').pop().match(/^(png|jpg|jpeg|gif)$/) : false;

  useEffect(
    () => fetchBase64Image(),
    [absolutePath]
  );

  const fetchBase64Image = () => {
    serviceFilePreview(absolutePath)
      .then(
        (response: any) => {
          response === undefined || response.error ? setImageBase64(notAvailablePic) : setImageBase64('data:@file/' + (matchFileType(absolutePath)) + ';base64,' + response?.data);
        }
      )
      .catch((error: any) => {
        console.log('Error serviceFilePreview', error);
      });
  };

  return (
    <div>
      <div className="e-card" id="basic">
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-header-title"> {imageName}</div>
          </div>
        </div>
        <div className="e-card-content">
          <Image src={isImage ? imageBase64 : notAvailablePic} size="large"></Image>
        </div>
      </div>
    </div>
  );
};

const matchFileType = (stringValue) => {
  const check_png = stringValue.toLowerCase().includes('.png');
  const check_jpg = stringValue.toLowerCase().includes('.jpg');
  const check_jpeg = stringValue.toLowerCase().includes('.jpeg');
  const check_gif = stringValue.toLowerCase().includes('.gif');
  switch (true) {
  case check_png:
    return 'png';
  case check_jpg:
    return 'jpg';
  case check_jpeg:
    return 'jpeg';
  case check_gif:
    return 'gif';
  }
};

export default ImageCard;
