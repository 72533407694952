import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Grid } from 'semantic-ui-react';
import { GridStyled } from '../styles';


export const renderCardTemplate = (args, navData) => {
  return ReactDOMServer.renderToStaticMarkup(
    <GridStyled css={navData.widgetData.style}>
      {navData.widgetData.sections.map((section) => (
        <Grid.Row
          columns={section.fieldSets.length}
          key={section.key}
          className={section.class}
        >
          {section.fieldSets.map((fieldSet) => (
            <Grid.Column
              key={fieldSet.key}
              className={fieldSet.class}
            >
              {fieldSet.fields.map((field) => (
                <Grid.Row
                  key={field.key}
                  className={field.class}
                >
                  <div key={field.id}>{args[field.attribute.name]}</div>
                </Grid.Row>
              ))}
            </Grid.Column>
          ))}
        </Grid.Row>
      ))}
    </GridStyled>
  );
};
