import { toast } from 'react-toastify';
import React from 'react';
import './styles.css';

export const notifyDebugError = (errorMessage, location) => {
  toast.error(
    <>
      <div className='toast-text-color-red'>{errorMessage}</div>
      at<br />
      <div className='toast-text-color-green'>{location}</div>
    </>,
    {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true
    }
  );
};