import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { notifyError } from '../../../components/Toasts/toast.error';

const fetchTreeGridData = async (setTreeGridData, params, widgetData) => {
  try {
    const response = await runDynamicQuery(params, widgetData);
    setTreeGridData(response);
    return response;
  } catch (error) {
    notifyError(`error fetch treegrid data: ${error}`);
  }
};

export default fetchTreeGridData;